@font-face {
  font-family: "Artilla";
  src: url("fonts/Artilla.ttf");
}

@font-face {
  font-family: "Autumn";
  src: url("fonts/Autumn-in-November.ttf");
}

@font-face {
  font-family: "SinhalaMN";
  src: url("fonts/SinhalaMN.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.autumn {
  font-family: "Autumn", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.artilla {
  font-family: "Artilla", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sinhala {
  font-family: "SinhalaMN", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline: none;
  outline-width: 0 !important;
}

.scroll-indicator {
  animation: updown 1s infinite alternate;
  -webkit-animation: updown 1s infinite alternate;
}

@-webkit-keyframes updown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes updown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
